<template>
  <PageSeoData
    :title="
      $t('page_seo_data.app.title', {
        brand: brandWithCapitalizeFirstLetter,
      })
    "
    :description="
      $t('page_seo_data.app.description', {
        brand: brandWithCapitalizeFirstLetter,
      })
    "
  />
  <OurApps />
</template>

<script setup lang="ts">
import OurApps from "~/components/sections/OurApps.vue";
import PageSeoData from "~/components/pageSeoData/PageSeoData.vue";

const {
  public: { brandWithCapitalizeFirstLetter },
} = useRuntimeConfig();
</script>
